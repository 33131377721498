import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { watch } from 'vue';
import { useSignStore } from '@stores/useSignStore';

export default defineNuxtRouteMiddleware(() => {
  const signStore = useSignStore();
  let allowedAccess = false;

  watch(
    [
      () => signStore.user,
    ],
    () => {
      if (signStore.user) {
        allowedAccess = true;
      }
    },
    {
      immediate: true,
    },
  );

  if (!allowedAccess) {
    return navigateTo({ path: '/aanbod' });
  }
});
